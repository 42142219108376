/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../css/app.css";
import "../css/responsive.css";

const Layout = ({ children }) => {
  // const buttonChatLoader = () => {
  //   const dfMessenger = document.querySelector("df-messenger");
  //   if (dfMessenger) {
  //     dfMessenger.sendQuery("hi");
  //   }
  // };

  return (
    <>
      <Helmet>
        <script
          src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"
          type="text/javascript"
          data-document-language="true"
        ></script>
        <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/360b1bcb-ef65-465f-91f3-502ac89da3bb/OtAutoBlock.js"
        ></script>
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="360b1bcb-ef65-465f-91f3-502ac89da3bb"
        ></script>
      </Helmet>
      {children}

      {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        إعدادات ملف تعريف الارتباط
      </button> */}
    </>
  );
};

export default Layout;
